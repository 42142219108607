import React from "react";
import Discovermore from "../Components/Discovermore";
import Footer from "../Components/Footer";
function Discover(){
    return(
        <div>
            <Discovermore/>
            <Footer/>

        </div>
    )
}
export default Discover