import React from "react";
import MobileApp from "../Components/MobileApp";
import Footer from "../Components/Footer";
function mobile(){
    return(
        <div>
            <MobileApp/>
            <Footer/>
        </div>
    )
}
export default mobile