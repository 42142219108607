import React from "react";
import "../Assets/Css/Contact.css"

function Contactpage(){
    return(
        <div className="container-fluid contactpage-bg"> 
        <div className="row">
        {/* <div className=" container-fluid  text-center contactus-text" data-aos="fade-in">
            <h3 className="fs-5 mt-5   contactTitle text-uppercase ">Contact Us</h3>
            <h2 className="display-5 mb-4 mb-md-5 mb-xl-8 contact contactSubTitle" >We're always on the lookout to work with new clients. Please get in touch in one of the following ways.</h2>
        </div> */}
        </div>
    </div>
    )
}
export default Contactpage